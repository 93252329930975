import { gsap } from "gsap";

const lightForeground = {
	r: 0xe9,
	g: 0xeb,
	b: 0xee,
};

const darkForeground = {
	r: 0x3c,
	g: 0x3f,
	b: 0x41,
};

const runNetwork = function (input) {
	const net = { layers: [{ r: {}, g: {}, b: {} }, { 0: { bias: 22.1948965196315, weights: { r: -29.476690791980268, g: -3.240460587969655, b: -6.6557882930459655 } }, 1: { bias: -6.456984451798189, weights: { r: 15.983818860567578, g: 18.447488704551105, b: -9.247586001834877 } }, 2: { bias: -6.9721750085936085, weights: { r: -1.3064920555229071, g: 11.341868240939274, b: 1.1495976584083387 } } }, { black: { bias: -12.257311598635427, weights: { 0: -19.856747339455865, 1: 12.127570396282998, 2: 28.296934901309456 } } }], outputLookup: true, inputLookup: true };
	let output = {};

	for (let i = 1; i < net.layers.length; ++i) {
		const layer = net.layers[i];
		output = {};

		// eslint-disable-next-line no-loop-func
		Object.keys(layer).forEach(id => {
			const node = layer[id];
			let sum = node.bias;

			Object.keys(node.weights).forEach(iid => {
				sum += node.weights[iid] * input[iid];
			});

			output[id] = (1 / (1 + Math.exp(-sum)));
		});

		input = output;
	}

	return output;
};

// see https://stackoverflow.com/a/41491220
// see https://stackoverflow.com/a/46470178
export const foregroundFromBackground = ({ r, g, b }) => {
	return runNetwork({ r, g, b }).black > 0.5 ? darkForeground : lightForeground;
};

const shade = ({ r, g, b }, amount) => ({
	r: r * (100 + amount) / 100,
	g: g * (100 + amount) / 100,
	b: b * (100 + amount) / 100,
});

export const darken = (color, amount = 40) => shade(color, -amount);

export const colors = [
	{
		r: 0x23,
		g: 0x9e,
		b: 0xb9,
	},
	{
		r: 0x56,
		g: 0x2a,
		b: 0x3a,
	},
	//
	{
		r: 0x43,
		g: 0x61,
		b: 0xee,
	},
	//
	{
		r: 0x2b,
		g: 0x3d,
		b: 0xcc,
	},
	//
	{
		r: 0x00,
		g: 0xa8,
		b: 0x78,
	},
];

const randomItem = items => {
	const index = Math.floor(Math.random() * items.length);
	return items[index];
};

export const changeColor = (scene, lines) => {
	const color = randomItem(colors);

	[scene.background, lines.color].forEach(x => gsap.set(x, x));

	const tl = gsap.timeline({
		paused: true,
		defaults: {
			duration: 0.5,
		},
	});

	tl.to(scene.background, {
		r() {
			return color.r / 255;
		},
		g() {
			return color.g / 255;
		},
		b() {
			return color.b / 255;
		},
	}, "<");

	tl.to(lines.color, {
		r() {
			return color.r / 255;
		},
		g() {
			return color.g / 255;
		},
		b() {
			return color.b / 255;
		},
	}, "<");

	tl.play();
};

