import { WebGLRenderer, sRGBEncoding } from 'three';

export default function createRenderer() {
    const renderer = new WebGLRenderer( { antialias: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.outputEncoding = sRGBEncoding;
    renderer.xr.enabled = true;

    return renderer;
}
