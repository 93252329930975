import '../styles/index.scss';

import { VRButton } from 'three/addons/webxr/VRButton.js';
import { XRControllerModelFactory } from 'three/addons/webxr/XRControllerModelFactory.js';

import createScene from './scene';
import createCamera from './camera';
import createRenderer from './renderer';
import createRacket from './racket';
import createBall from './ball';
import { changeColor } from "./colors";

// Scene, camera & renderer
const { scene, lineMat } = createScene();
const camera = createCamera();
const renderer = createRenderer();
const { racket /*, racketMaterial */ } = createRacket();
const { showBall, ball /*, ballMaterial */ } = createBall();
scene.add(racket);
scene.add(ball);

// DEBUG
const controllerModelFactory = new XRControllerModelFactory();
const controllerGrip = renderer.xr.getControllerGrip( 0 );
controllerGrip.add( controllerModelFactory.createControllerModel( controllerGrip ) );
scene.add( controllerGrip );

// Controller
const controller = renderer.xr.getController(0);

renderer.setAnimationLoop(render);
document.body.appendChild( renderer.domElement );
document.body.appendChild( VRButton.createButton( renderer ) );


changeColor(scene, lineMat);
setTimeout(showBall, 10000);

function render() {
  handleController();
  renderer.render( scene, camera );
}

function handleController() {
  racket.position.set(controller.position.x, controller.position.y, controller.position.z);
  racket.rotation.set(controller.rotation.x, controller.rotation.y, controller.rotation.z);

  // TEST
  ball.position.set(controller.position.x, controller.position.y + 0.2, controller.position.z);
}

window.addEventListener( 'resize', onWindowResize );


function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
}