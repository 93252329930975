import { Scene, Color, LineSegments, LineBasicMaterial, HemisphereLight, DirectionalLight } from 'three';

import { BoxLineGeometry } from 'three/addons/geometries/BoxLineGeometry.js';

export default function createScene() {
    const scene = new Scene();
    scene.background = new Color(0x4361ee);

	const lineMat = new LineBasicMaterial({ color: 0x4361ee });
    const room = new LineSegments(
      new BoxLineGeometry(6, 6, 6, 10, 10, 10),
      lineMat
   );
    room.geometry.translate(0, 3, 0);
    scene.add(room);

    scene.add(new HemisphereLight(0x606060, 0x404040));

    const light = new DirectionalLight(0xffffff);
    light.position.set(1, 1, 1).normalize();
    scene.add(light);

    return { scene, lineMat };
}