import { IcosahedronGeometry, Mesh, Color } from 'three';
import { FBMDissolveMaterial } from "threejs-shader-materials";
import { gsap } from "gsap";


export default function createBall() {
    const geometry = new IcosahedronGeometry( 0.02, 3 );
    // const material = new MeshLambertMaterial( { color: 0xefede9 } );
    const material = new FBMDissolveMaterial();

    Object.assign(material, {
        color: new Color(0xffffff),
        transparent: true,
        uniformOpacity: 1,
        emissive: new Color(0x000000),
        edgeColor: new Color(0x23E9B9),
        progress: 0,
        tiles: 2,
        edgeWeight: 0.2,
        hashLoop: 8,
        amp: 0.5,
    });

    const ball = new Mesh( geometry, material );

    const showBall = () => {
        gsap.set(material, {
            ...material,
            x: material.progress,
        });

        gsap.to(material, {
            x: 1,
            duration: 2.5,
            onUpdate(){
                material.progress = material.x;
            },
        });
    };

    const hideBall = () => {
        gsap.set(material, {
            ...material,
            x: material.progress,
        });

        gsap.to(material, {
            x: 0,
            duration: 2.5,
            onUpdate(){
                material.progress = material.x;
            },
        });
    };

    return {
        ball,
        hideBall,
        showBall,
        ballMaterial: material,
    };
}