import { CylinderGeometry, MeshLambertMaterial, Mesh, Group, BoxGeometry, MathUtils, Color } from 'three';

import { CellularNoiseMaterial } from "threejs-shader-materials";

export default function createRacket() {
    const racket = new Group();
    const racketContainer = new Group();

    // Head
    const headGeometry = new CylinderGeometry( 0.08, 0.08, 0.01, 32 );
    const headMaterial = new CellularNoiseMaterial();
    Object.assign(headMaterial, {
        transparent: true,
        color: new Color(0x33f5ff),
        uniformOpacity: true,
        emissive: new Color(0x0),
        isAnimate: true,
        speed: 2,
        cellularNoise: 3,
        divisionScaleX: 2,
    });

    // const headMaterial = new MeshLambertMaterial( { color: 0xefede9 } );
    const headMesh = new Mesh( headGeometry, headMaterial );
    headMesh.position.z = -0.05;
    // headMesh.rotation.x = MathUtils.degToRad( 0 );
    racket.add(headMesh);

    // Handle
    const handleGeometry = new BoxGeometry( 0.025, 0.02, 0.09 );
    const handleMaterial = new MeshLambertMaterial( { color: 0x9e9c97 } );
    const handleMesh = new Mesh( handleGeometry, handleMaterial ) ;
    handleMesh.position.z = 0.055;
    racket.add(handleMesh);

    racket.position.y = 0.02;
    racket.position.z = 0.0015;
    racket.rotation.x = MathUtils.degToRad( 35 );
    racketContainer.add(racket);

    return {
        racket: racketContainer,
        racketMaterial: headMaterial,
    };
}